module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T7TMLWV","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"logopwa","short_name":"Orlando Florida Web Development","start_url":"/","background_color":"#FFFFFF","theme_color":"#00B8EA","display":"minimal-ui","icon":"src/images/faviconLogopwa.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
